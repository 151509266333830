.App {
    text-align: center;
}

body {
    padding: 0;
    margin: 0;
}

#tsparticles {
    position: fixed;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    left: 0;
    top: 0;
    z-index: -1;
}