.project {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: "forma-djr-display", sans-serif;
    color: #1d1d1f;
}

.project h1 {
    font-size: 35px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.project img {
    width: 400px;
    border-radius: 10px;
}

.project p {
    font-size: 24px;
    margin-bottom: 5px;
}

.projectDescription {
    width: 75%;
}

.projectDescription p {
    font-size: 20px;
    line-height: 1.5;
}

.projectDescription a {
    color: inherit;
    /* This will make the link inherit the text color */
    text-decoration: none;
    /* Remove underline if needed */
    font-weight: bold;
}

.projectDescription a:hover {
    text-decoration: underline;
}

.project svg {
    font-size: 60px;
    color: #1d1d1f;
}

.project button {
    background-color: transparent;
    color: #1d1d1f;
    font-weight: bold;
    margin-top: 30px;
    border: 2px solid #1d1d1f;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
    cursor: pointer;
}

.project button:hover {
    background-color: #1d1d1fe7;
    color: #fff;
}

@media only screen and (max-width: 650px) {
    .project img {
        width: 300px;
    }
}