.gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    /* Adjust the minimum and maximum width as needed */
    gap: 10px;
    /* Adjust this value for spacing between photos */
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
}

.gallery-item {
    overflow: hidden;
}

.gallery-item img {
    width: 100%;
    height: auto;
}