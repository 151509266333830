.home {
    width: 100%;
    align-items: center;
}

.about {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #1d1d1f;
    font-family: "Helvetica", sans-serif;
}

.about h2 {
    font-family: "forma-djr-display", sans-serif;
    font-size: 48px;
    color: #1d1d1f;
    margin: 40px auto;
}

.intro {
    font-size: 20px;
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    line-height: 1.5;

}

.intro p {
    background-color: #ffffff;
}

.buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.buttons a {
    margin: 10px;
}

@media only screen and (max-width: 900px) {
    .about h2 {
        font-size: 30px;
    }

    .intro {
        text-align: center;
        font-size: 18px;
        width: 75%;
    }

}

@media only screen and (max-width: 600px) {
    .about h2 {
        font-size: 30px;
    }
}