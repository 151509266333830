.projects {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: "forma-djr-display", sans-serif;
    color: #1d1d1f;
}

.projectList {
    width: 90vw;
    height: auto;
    display: flex;
    grid-template-columns: 1fr 1fr 1fr;
    place-items: center;
    justify-content: center;
}

/* MENU ITEM STYLING */

.projectItem {
    border-radius: 15px;
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    margin: 40px 40px 0px 40px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
}

.projectItem:hover {
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
    transition: 0.3s ease-in;
    cursor: pointer;
}

.projectItem .bgImage {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.projectItem h1 {
    font-size: 25px;
    margin: auto;
}

.filterButtons {
    width: 90vw;
    margin: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}

.filterButtons button {
    background-color: transparent;
    color: #1d1d1f;
    font-weight: bold;
    border: 2px solid #1d1d1f;
    padding: 10px 20px;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
    cursor: pointer;
}

.filterButtons button.active {
    background-color: #1d1d1f;
    color: #fff;
}

.filterButtons button:hover {
    background-color: #1d1d1fe7;
    color: #fff;
}

.projectList {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

@media only screen and (max-width: 1300px) {
    .projectList {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 800px) {
    .projectList {
        grid-template-columns: 1fr;
    }
}

@media only screen and (max-width: 800px) {
    .projectList {
        width: 100%;
    }

    .projectItem {
        width: 300px;
        height: 300px;
    }
}