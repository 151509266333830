.navbar {
    width: 100%;
    height: 100px;
    background: #fafafc;
}

.links {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    color: #323232;
}

.navbar a {
    color: #323232;
    text-decoration: none;
    margin: 20px;
    font-size: 25px;
    font-family: "forma-djr-display", sans-serif;
}

.navbar .MD {
    font-weight: bold;
    margin-left: 40px;
}

.toggleButton {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.toggleButton svg {
    font-size: 50px;
}

.toggleButton button {
    margin-right: 20px;
    background-color: transparent;
    border: none;
    color: #323232;
    cursor: pointer;
}

#open {
    height: 100vh;
}

#open .links {
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 100px;
}

#open a {
    width: 100%;
    text-align: center;
}

@media only screen and (max-width: 655px) {
    .toggleButton {
        display: flex;
    }

    #close a {
        display: none;
    }
}

@media only screen and (min-width: 655px) {
    .toggleButton {
        display: none;
    }

    .hiddenLinks {
        display: none;
    }

    #open {
        height: 100px;
    }

    #open .links {
        flex-direction: row;
    }
}